import React, { useState } from 'react';
import truncate from 'lodash/truncate';
import AliceCarousel from 'react-alice-carousel';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { richText } from '../../../util/richText';
import { INQUIRY_PROCESS_NAME, resolveLatestProcessName } from '../../../transactions/transaction';
import { transformToEmbedUrl } from '../../../util/transformToEmbedUrl';
import { Heading, Modal, IconCarouselArrow, InlineTextButton } from '../../../components';

import AzureImageDisplay from '../../../components/AzureImageDisplay/AzureImageDisplay';
import UserCardForTreatment from '../UserCardForTreatment/UserCardForTreatment';
import InquiryForm from '../InquiryForm/InquiryForm';
import defaultConfig from '../../../config/configDefault';

import css from './TreatmentListingContent.module.css';

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the author section of the ListingPage.
const BIO_COLLAPSED_LENGTH = 300;
const MIN_LENGTH_FOR_LONG_WORDS = 20;

const truncated = s => {
  return truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

const ExpandableBio = props => {
  const [expand, setExpand] = useState(false);
  const { className, bio } = props;
  const bioWithLinks = richText(bio, {
    linkify: true,
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
  });
  const truncatedBio = richText(truncated(bio), {
    linkify: true,
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  const handleShowMoreClick = () => {
    setExpand(true);
  };
  const showMore = (
    <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
      <FormattedMessage id="UserCard.showFullBioLink" />
    </InlineTextButton>
  );
  return (
    <p className={className}>
      {expand ? bioWithLinks : truncatedBio}
      {bio.length >= BIO_COLLAPSED_LENGTH && !expand ? showMore : null}
    </p>
  );
};

const SectionAuthorMaybe = props => {
  const {
    listing,
    listingFields,
    authorDisplayName,
    onContactUser,
    isInquiryModalOpen,
    onCloseInquiryModal,
    sendInquiryError,
    sendInquiryInProgress,
    onSubmitInquiry,
    currentUser,
    onManageDisableScrolling,
    location
  } = props;

  if (!listing?.author) {
    return null;
  }

  const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isInquiryProcess = processName === INQUIRY_PROCESS_NAME;

  const {
    title,
    description,
    publicData = {},
  } = listing?.attributes;

  const tagsColors = defaultConfig?.tagsColors || []
  const techniqueTypes = publicData?.techniqueTypes;
  const techniqueTypesOptions = listingFields.find(f => f.key === 'techniqueTypes')?.enumOptions;
  const techniqueTypesLabels = techniqueTypesOptions.filter(o => techniqueTypes?.find(t => t.includes(o.option)));

  const videoUrl = publicData?.videoUrl;
  const uploadedImages = publicData?.uploadedImages;

  const videoItem = videoUrl && (
    <div className={classNames(css.productGalleryItem, css.productGalleryVideo)}>

      <iframe
        style={{ borderRadius: "var(--borderRadiusFull)" }}
        width="100%"
        height={425}
        src={transformToEmbedUrl(videoUrl)}
        title="video"
        frameBorder={0}
        allowFullScreen
      />
    </div>
  );

  const photoItems = !!uploadedImages?.length ? uploadedImages.map(image => {
    return (
      <div className={classNames(css.productGalleryItem, css.productGalleryImage)}>
        <AzureImageDisplay
          key={image}
          value={image}
        />
      </div>
    )
  }) : [];

  const items = [videoItem, ...photoItems]

  const [currentIndex, setCurrentIndex] = useState(0);
  const totalItems = items?.length;

  const prevButton = () => (
    <button
      onClick={() => slidePrev()}
      disabled={currentIndex === 0}
    >
      <IconCarouselArrow direction="left" disabled={currentIndex === 0} />
    </button>
  );

  const nextButton = () => (
    <button
      onClick={() => slideNext()}
      disabled={currentIndex === totalItems - 1}
    >
      <IconCarouselArrow direction="right" disabled={currentIndex === totalItems - 1} />
    </button>
  );

  const slidePrev = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const slideNext = () => {
    setCurrentIndex((prev) => (prev < totalItems - 1 ? prev + 1 : prev));
  };

  const handleSlideChange = ({ item }) => {
    setCurrentIndex(item);
  };

  return (
    <section id="author" className={css.sectionAuthor}>

      <Heading as="h2" rootClassName={classNames(css.sectionHeading, css.sectionHeadingBig)}>
        <FormattedMessage id="ListingPage.meetYourPractitionerTitle" />
      </Heading>

      <div className={css.sectionAuthorContent}>
        <UserCardForTreatment
          title={title}
          listing={listing}
          user={listing.author}
          currentUser={currentUser}
          onContactUser={onContactUser}
          showContact={!isInquiryProcess}
          onManageDisableScrolling={onManageDisableScrolling}
          location={location}
        />

        <div className={css.productGallery}>
          {!!items.filter(i => !!i)?.length && (
            <AliceCarousel
              items={items.filter(i => !!i)}
              infinite={false}
              activeIndex={currentIndex}
              onSlideChanged={handleSlideChange}
              renderPrevButton={prevButton}
              renderNextButton={nextButton}
              disableDotsControls
              disableButtonsControls={items.filter(i => !!i)?.length <= 1}
            />
          )}
        </div>

        <Modal
          id="ListingPage.inquiry"
          contentClassName={css.inquiryModalContent}
          isOpen={isInquiryModalOpen}
          onClose={onCloseInquiryModal}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <InquiryForm
            className={css.inquiryForm}
            submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
            listingTitle={title}
            authorDisplayName={authorDisplayName}
            sendInquiryError={sendInquiryError}
            onSubmit={onSubmitInquiry}
            inProgress={sendInquiryInProgress}
          />
        </Modal>
      </div>

      {!!techniqueTypesLabels?.length && (
        <ul className={css.techniqueTypesList}>
          {techniqueTypesLabels.map((t, i) => {

            let colorIndex = i % tagsColors.length;

            return (
              <li
                key={i}
                style={{ backgroundColor: tagsColors[colorIndex] }}
                className={css.techniqueTypesListItem}
              >
                {t.label}
              </li>
            );
          })}
        </ul>
      )}

      {!!description && (
        <>
          <Heading as="h2" rootClassName={css.sectionHeading}>
            <FormattedMessage id="ListingPage.aboutMeTitle" />
          </Heading>
          <ExpandableBio className={css.bio} bio={description} />
        </>
      )}
    </section>
  );
};

export default SectionAuthorMaybe;
